.App {
  text-align: center;
  background-color: #031f14;
}

body {
  background-color: #031f14;
}

.App-link {
  color: #E9C447;
}

.gray-button {
  .ant-btn-primary {
    color: white;
    font-weight: 600;
    background-color: gray !important;
  }
  .ant-btn-primary:hover {
    color: #38383e;
    font-weight: 600;
    background-color: gray !important;
  }
}

.ant-btn-primary:hover {
  color: black;
  font-weight: 600;
  background-color: #E9C447 !important;
}

.ant-btn-primary {
  color: black;
  font-weight: 600;
  background-color: #E9C447;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #0e3e25 !important;
  width: 70%;

  .ant-menu-title-content {
    color: #E9C447 !important;
  }
}

.ant-menu {
  text-align: -webkit-center;
}

.ant-menu-title-content {
  color: #aeaeae !important;
}

.ant-layout .ant-layout-sider-zero-width-trigger::after {
  top: 1.25em;
  background: #082a1a !important;
  color: #E9C447 !important;
}

@media only screen and (min-width: 577px) {

  .mobile-visible {
    display: none;
  }

}
@media only screen and (max-width: 576px) {
}